import React, { Component } from 'react'
import logo from '../images/minniesLogo.PNG';

class VolunteerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isValidated = this.isValidated.bind(this);
  }

  saveToSession(elementName) {

    sessionStorage.setItem(elementName, document.getElementById(elementName).value);
    sessionStorage.setItem('state1', document.getElementById('state1').value);
    
  }

  ValidateDOB() {

    var myBirthDate = document.getElementById("birthday1");

   // var regex = /^((((0[13578])|(1[02]))[\/]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\/]?(([0-2][0-9])|(30)))|(02[\/]?[0-2][0-9]))[\/]?\d{4}$/g
   var regex = /((^(10|12|0?[13578])([/])(3[01]|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(11|0?[469])([/])(30|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(2[0-8]|1[0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(29)([/])([2468][048]00)$)|(^(0?2)([/])(29)([/])([3579][26]00)$)|(^(0?2)([/])(29)([/])([1][89][0][48])$)|(^(0?2)([/])(29)([/])([2-9][0-9][0][48])$)|(^(0?2)([/])(29)([/])([1][89][2468][048])$)|(^(0?2)([/])(29)([/])([2-9][0-9][2468][048])$)|(^(0?2)([/])(29)([/])([1][89][13579][26])$)|(^(0?2)([/])(29)([/])([2-9][0-9][13579][26])$))/g

    if (regex.test(myBirthDate.value)) {
      myBirthDate.style.borderColor = "black";
      return true;

    } else {

        alert("Enter birthdate in mm/dd/yyyy format ONLY.  Example is '02/28/1977'.");
        myBirthDate.style.borderColor = "red";
        return false;
    }

}

  componentDidMount() {

    var urlParams = new URLSearchParams(window.location.search);
    var rName = urlParams.get('rname');
    
    if(rName != null && rName != "") {
       let referral = document.getElementById('group1');
       referral.disabled = true;
       referral.value = rName.substr(0,30);      
    }
    
    if(sessionStorage.getItem('fname1')){
       let field = document.getElementById('fname1');
       field.value = sessionStorage.getItem('fname1');
    }

    if(sessionStorage.getItem('lname1')){
      let field = document.getElementById('lname1');
      field.value = sessionStorage.getItem('lname1');
   }

   if(sessionStorage.getItem('birthday1')){
    let field = document.getElementById('birthday1');
    field.value = sessionStorage.getItem('birthday1');
 }

 if(sessionStorage.getItem('gender1')){
  let field = document.getElementById('gender1');
  field.value = sessionStorage.getItem('gender1');
}

if(sessionStorage.getItem('email1')){
  let field = document.getElementById('email1');
  field.value = sessionStorage.getItem('email1');
}

if(sessionStorage.getItem('group1')){
  let field = document.getElementById('group1');
  field.value = sessionStorage.getItem('group1');
}

if(sessionStorage.getItem('phone1')){
  let field = document.getElementById('phone1');
  field.value = sessionStorage.getItem('phone1');
}

if(sessionStorage.getItem('address1')){
  let field = document.getElementById('address1');
  field.value = sessionStorage.getItem('address1');
}

if(sessionStorage.getItem('address2')){
  let field = document.getElementById('address2');
  field.value = sessionStorage.getItem('address2');
}

if(sessionStorage.getItem('city1')){
  let field = document.getElementById('city1');
  field.value = sessionStorage.getItem('city1');
}

if(sessionStorage.getItem('state1')){
  let field = document.getElementById('state1');
  field.value = sessionStorage.getItem('state1');
}

if(sessionStorage.getItem('zip1')){
  let field = document.getElementById('zip1');
  field.value = sessionStorage.getItem('zip1');
}

if(sessionStorage.getItem('languages1')){
  let field = document.getElementById('languages1');
  field.value = sessionStorage.getItem('languages1');
}

if(sessionStorage.getItem('crimeyn1')){
  let field = document.getElementById('crimeyn1');
  field.value = sessionStorage.getItem('crimeyn1');
}

if(sessionStorage.getItem('crimeother1')){
  let field = document.getElementById('crimeother1');
  field.value = sessionStorage.getItem('crimeother1');
}

// if(sessionStorage.getItem('years1')){
//   let field = document.getElementById('years1');
//   field.value = sessionStorage.getItem('years1');
// }

if(sessionStorage.getItem('textyn1')){
  let field = document.getElementById('textyn1');
  field.value = sessionStorage.getItem('textyn1');
}

   }

   isValidated() {

    let formValidated = true;

    if(sessionStorage.getItem('fname1')){
      let field = document.getElementById('fname1');
      if(field.value == "") {
      formValidated = false;
      field.style.borderColor = "red";
      }
      else {
        field.style.borderColor = "black";
      }

   }
   else{
    formValidated = false;
    let field = document.getElementById('fname1');
    field.style.borderColor = "red";
   }

   if(sessionStorage.getItem('lname1')){
     let field = document.getElementById('lname1');
     if(field.value == "") {
      formValidated = false;
      field.style.borderColor = "red";
      }
      else {
        field.style.borderColor = "black";
      }
   }
   else{
    let field = document.getElementById('lname1');
    formValidated = false;
    field.style.borderColor = "red";
   }

  if(sessionStorage.getItem('birthday1')){
   let field = document.getElementById('birthday1');
   if(field.value == "") {
    formValidated = false;
    field.style.borderColor = "red";
    }
    if(!this.ValidateDOB()){
      formValidated = false;
    field.style.borderColor = "red";
    
    }

    else {
      field.style.borderColor = "black";
    }
 }
 else{
  formValidated = false;
  let field = document.getElementById('birthday1');
  field.style.borderColor = "red";
 }
   
if(sessionStorage.getItem('gender1')){
 let field = document.getElementById('gender1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('gender1');
field.style.borderColor = "red";
}
 
if(sessionStorage.getItem('email1')){
 let field = document.getElementById('email1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('email1');
field.style.borderColor = "red";
}
 

 
if(sessionStorage.getItem('phone1')){
 let field = document.getElementById('phone1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('phone1');
field.style.borderColor = "red";
}
 
if(sessionStorage.getItem('address1')){
 let field = document.getElementById('address1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('address1');
field.style.borderColor = "red";
}

 
if(sessionStorage.getItem('city1')){
 let field = document.getElementById('city1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('city1');
field.style.borderColor = "red";
}
 
if(sessionStorage.getItem('state1')){
 let field = document.getElementById('state1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('state1');
field.style.borderColor = "red";
}
 
if(sessionStorage.getItem('zip1')){
 let field = document.getElementById('zip1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('zip1');
field.style.borderColor = "red";
}
 
if(sessionStorage.getItem('crimeyn1')){
 let field = document.getElementById('crimeyn1');
 field.value = sessionStorage.getItem('crimeyn1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('crimeyn1');
field.style.borderColor = "red";
}
 
// if(sessionStorage.getItem('crimeother1')){
//  let field = document.getElementById('crimeother1');
//  if(field.value == "")
//  formValidated = false;
// }
// else{
//   formValidated = false;
//  }
 


if(sessionStorage.getItem('textyn1')){
 let field = document.getElementById('textyn1');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('textyn1');
field.style.borderColor = "red";
}
 

  if(formValidated == false) 
  alert("Please complete all required fields that are highlighted in red.");

    return formValidated;

   }
  
  componentWillUnmount() {
 
  sessionStorage.setItem('state1', document.getElementById('state1').value);
  }

  render() {

    const divStyle = {
      margin : '0px',
      padding : '3px'
      };
  
      const addBordering = {
        border : '1px solid'
        };

    return (
      <div>
  <div className="row">
        <div className="col-sm-3">
        <img src={logo} alt="Logo" />
        </div>
        <div className="col-sm-6">
        <h5 style={{textAlign: "center"}}>NEW VOLUNTEER SIGN UP FORM</h5>
        <h6 style={{textAlign: "center"}}>Thank you for serving with us!!</h6>
        </div>
        </div>
<br></br>
        <div  className="row">
          <div style={divStyle} className="col-sm-3">
            FIRST NAME*: 
           </div> 
           <div style={divStyle} className="col-sm-3">
            <input style={addBordering} onChange={() => this.saveToSession('fname1')}  type="text" id="fname1" />
          </div>

          <div style={divStyle} className="col-sm-3">
            LAST NAME*: 
            </div> 
           <div style={divStyle} className="col-sm-3">
            <input style={addBordering} onChange={() => this.saveToSession('lname1')}  type="text" id="lname1" />
          </div>
        </div>

        <div className="row">
          <div style={divStyle}  className="col-sm-3">
            BIRTHDAY (mm/dd/yyyy)*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onBlur={() => this.ValidateDOB()}  onChange={() => this.saveToSession('birthday1')}  type="text" id="birthday1" />
          </div>

          <div style={divStyle}  className="col-sm-3">
            GENDER*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <select style={addBordering} onChange={() => this.saveToSession('gender1')}  id="gender1">
            <option value=""></option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
        </div>

        <div className="row">

          <div style={divStyle}  className="col-sm-3">
            EMAIL*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('email1')}  type="text" id="email1" />
          </div>

          <div style={divStyle}  className="col-sm-3">
            GROUP/ORGANIZATION:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('group1')}  type="text" id="group1" />
          </div>

          
        </div>

        <div className="row">
          <div style={divStyle}  className="col-sm-3">
            ADDRESS 1*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('address1')}  type="text" id="address1" />
          </div>

          <div style={divStyle}  className="col-sm-3">
            ADDRESS 2:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('address2')}  type="text" id="address2" />
          </div>
        </div>

        <div className="row">
        <div style={divStyle}  className="col-sm-3">
            CITY*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('city1')}  type="text" id="city1" />
          </div>
          <div style={divStyle}  className="col-sm-3">
            STATE*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('state1')}  type="text" defaultValue="TX" id="state1" />
          </div>
        </div>

        <div className="row">
        <div style={divStyle}  className="col-sm-3">
            ZIP*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('zip1')} type="text" id="zip1" />
          </div>
          <div style={divStyle}  className="col-sm-3">
            CELL PHONE*:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('phone1')}  type="phone1" id="phone1" />
          </div>
          </div>

        <div className="row">
          <div style={divStyle}  className="col-sm-3">
            OPT-IN FOR TEXT MESSAGES?*:
            </div> 
           <div style={divStyle} className="col-sm-3">
              <select style={addBordering} onChange={() => this.saveToSession('textyn1')}  id="textyn1">
            <option value=""></option>
              <option value="Y">YES</option>
              <option value="N">NO</option>
            </select>
          </div>
          
          <div style={divStyle}  className="col-sm-3">
            OTHER LANGUAGES SPOKEN:
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('languages1')}  type="text" id="languages1" />
          </div>
        </div>

        <div className="row">
          <div style={divStyle}  className="col-sm-3">
            EVER BEEN CONVICTED OF A CRIME?*
            </div> 
           <div style={divStyle} className="col-sm-3">
              <select style={addBordering} onChange={() => this.saveToSession('crimeyn1')}  id="crimeyn1">
            <option value=""></option>
              <option value="Y">YES</option>
              <option value="N">NO</option>
            </select>
          </div>
          
          <div style={divStyle}  className="col-sm-3">
            If YES to crime convicted, please explain: 
            </div> 
           <div style={divStyle} className="col-sm-3">
             <input style={addBordering} onChange={() => this.saveToSession('crimeother1')}  type="text" id="crimeother1" />
          </div>
        </div>
        {/* <div className="row">
        <div style={divStyle}  className="col-sm-3">
            Years Active:
            </div> 
           <div style={divStyle} className="col-sm-3">
              <input style={addBordering} onChange={() => this.saveToSession('years1')}  type="number" id="years1" />
          </div>
          </div> */}
          <br></br>
        </div>

    )
  }
}


export default VolunteerInfo