
import React, {Component} from 'react'
import logo from '../images/minniesLogo.PNG';
import {
  withRouter
} from 'react-router-dom'


class VolunteerSummarySubmit extends Component {
    constructor(props) {
      super(props);
    }
    
    submitVolunteer()  {
      document.getElementById('submitBtn').disabled = true;
      
      var obj = {
        FirstName : sessionStorage.getItem('fname1'),
        LastName : sessionStorage.getItem('lname1'),
        Birthday : sessionStorage.getItem('birthday1'),
        Gender : sessionStorage.getItem('gender1'),
        Email : sessionStorage.getItem('email1'),
        Group : sessionStorage.getItem('group1'),
        CellPhone : sessionStorage.getItem('phone1'),
        StreetAddress1 : sessionStorage.getItem('address1'),
        StreetAddress2 : sessionStorage.getItem('address2'),
        City : sessionStorage.getItem('city1'),
        State : sessionStorage.getItem('state1'),
        Zip : sessionStorage.getItem('zip1'),
        OtherLanguages : sessionStorage.getItem('languages1'),
        CrimeYN : sessionStorage.getItem('crimeyn1'),
        CrimeExplain : sessionStorage.getItem('crimeother1'),
        OptTextYN : sessionStorage.getItem('textyn1'),
        YearsActive : ""

    //    ProofResidenceBlob : window.btoa(sessionStorage.getItem('blobImage').replace("data:image/png;base64,",""))//.toString().replace("data:image/png;base64,",""))
        } 
    
      fetch('https://www.invisionthat.com/minnies/api/values/SubmitVolunteer', {
       
       method: 'post',
       headers: {
            'Content-Type': 'application/json'
        },
    body: JSON.stringify(obj)
  }) 
  .then(response => response.json())
  .then(response1 => {
    sessionStorage.clear();
    this.props.history.push('/thankyou');
  }).catch((err) => {
    document.getElementById('submitBtn').disabled = false;
    alert("An unexpected error occurred when trying to submit your application.  Please try to submit again.  If you get this error again, please report issue to Minnie's Staff.  " + err)
  });
      }

     
  
    componentDidMount() {

      document.getElementById('submitBtn').disabled = false;
     
     
    }
  
    componentWillUnmount() {
     
    }
  
    
    render() {
      
      return (
         <div>
           <div className="row">
 <div className="col-sm-3">
        <img src={logo} alt="Logo" />
        </div>
        <div className="col-sm-8">
        <h4>New Volunteer summary page. Please verify info is correct before submitting.</h4>
        </div>
        </div>

         <hr />
          <h4>First Name: {sessionStorage.getItem('fname1')}</h4>
          <h4>Last Name: {sessionStorage.getItem('lname1')}</h4>
          <h4>Birthday: {sessionStorage.getItem('birthday1')}</h4>
          <h4>Gender: {sessionStorage.getItem('gender1')}</h4>
          <h4>Email: {sessionStorage.getItem('email1')}</h4>
          <h4>Group/Organization: {sessionStorage.getItem('group1')}</h4>
          <h4>Cell Phone: {sessionStorage.getItem('phone1')}</h4>
          <h4>Opt-In Text? {sessionStorage.getItem('textyn1')}</h4>
          <h4>Street Address 1: {sessionStorage.getItem('address1')}</h4>
          <h4>Street Address 2: {sessionStorage.getItem('address2')}</h4>
          <h4>City: {sessionStorage.getItem('city1')}</h4>
          <h4>State: {sessionStorage.getItem('state1')}</h4>
          <h4>Zip: {sessionStorage.getItem('zip1')}</h4>
          <h4>Other Languages Spoken: {sessionStorage.getItem('languages1')}</h4>
          <h4>Convicted of a crime? {sessionStorage.getItem('crimeyn1')}</h4>
          <h4>If Yes, please explain: {sessionStorage.getItem('crimeother1')}</h4>
          {/* <h4>Years Active: {sessionStorage.getItem('years1')}</h4> */}
          <br />
          <button id="submitBtn" className="btn btn-prev btn-primary btn-lg" onClick={()=> this.submitVolunteer()}>Submit Volunteer Application</button>
          <br></br><br></br>
       </div>
      )
    }
  }

  export default withRouter(VolunteerSummarySubmit)