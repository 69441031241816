import React from 'react';
import StepZilla from "react-stepzilla";
import './App.css';
import VolunteerInfo from './components/VolunteerInfo';

import './css/main.css';

import VolunteerSummarySubmit from './components/VolunteerSummarySubmit';


function App() {

  const steps =
    [
        {name: 'Enter Volunteer Info', component: <VolunteerInfo />},
        {name: 'Summary/Submit', component: <VolunteerSummarySubmit />},
    ]

    function saveToSession(step) {
  
    }
 
  return (
    
    <div>

<div className='step-progress container'>
        <StepZilla steps={steps} showSteps={true} stepsNavigation={true} hocValidationAppliedTo={[1]}
        prevBtnOnLastStep={true}   onStepChange={(step) => saveToSession(step)} />
    </div>
    </div>
  );
}

export default App;
