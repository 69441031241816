import React, {Component} from 'react'
import logo from '../images/minniesLogo.PNG';


class ThankYou extends React.Component {
  constructor(props) {
    super(props);
  }

  goBackHome(){

    this.props.history.push('/');
  }

  
  render() {
    return (
      <div>

<div className="row">
    <div className="col-sm-3">
           <img src={logo} alt="Logo" />
           </div>
           <div className="col-sm-8">
             <br></br>
           <h4>Your Application was successfully submitted.</h4>
           <br></br>
           <h6>Someone from Minnie's Staff will contact you if there are any issues.  You may now close this page.</h6>
           <h6>If you have any questions, please feel free to reach out at any time (972) 596-0253.</h6>
           <br></br>
           <br></br>

           <button id="submitBtn" className="btn btn-prev btn-primary btn-lg" onClick={()=> this.goBackHome()}>Go Back To HomePage</button>
           </div>
           </div>

      </div>
    );
  }
};

export default ThankYou;